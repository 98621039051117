var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index",style:({ minHeight: _vm.minHeight })},[_c('div',{staticStyle:{"margin-bottom":"-10px"},on:{"click":_vm.searchBtn}},[_c('van-search',{attrs:{"background":"#F6F6F6","readonly":true,"placeholder":"输入医学公式关键词进行搜索"}})],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item_view nav"},[_vm._m(0),_c('div',{staticStyle:{"border-right":"#C3C3C3 1px solid","margin":"0 15px 0 10px","height":"45px"}}),_c('van-swipe',{staticStyle:{"height":"50px","flex":"1"},attrs:{"autoplay":3000,"indicator-color":"#5169EF"}},_vm._l((_vm.news),function(item,index){return _c('van-swipe-item',{key:index,on:{"click":function($event){return _vm.routeBtn(item, 'YXGS_HOME_NEW_LIST')}}},[_c('div',{staticClass:"swipe_title",staticStyle:{"-webkit-line-clamp":"1"}},[_vm._v(" "+_vm._s(item.chineseName)+" ")]),_c('div',{staticClass:"swipe_title_sub"},[_vm._v(" "+_vm._s(item.englishName)+" ")])])}),1),(_vm.news.length !== 0)?_c('img',{staticStyle:{"height":"30px","right":"0","top":"0","position":"absolute"},attrs:{"src":require("../../assets/medical/upnew.png"),"alt":""}}):_vm._e()],1),_c('div',{staticClass:"item_view"},[_vm._m(1),_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":""},on:{"load":_vm.getRecommendList},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.recommend),function(item,index){return _c('div',{key:item.id,staticClass:"item_view_card",on:{"click":function($event){return _vm.routeBtn(item, 'YXGS_HOME_RECOMMEND_LIST')}}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"hot_index",style:({
                color:
                  index === 0
                    ? '#F21448'
                    : index === 1
                    ? '#D15C0E'
                    : index === 2
                    ? '#AB8111'
                    : '#000000',
              })},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',[_c('div',{staticClass:"swipe_title"},[_vm._v(" "+_vm._s(item.chineseName)+" ")]),_c('div',{staticClass:"swipe_title_sub"},[_vm._v(" "+_vm._s(item.englishName)+" ")])])]),(index !== _vm.recommend.length - 1)?_c('div',{staticStyle:{"border-bottom":"#F6F6F6 solid 1px","margin":"10px 0"}}):_vm._e()])}),0)],1),(_vm.days.length !== 0)?_c('div',{staticClass:"item_view"},[_vm._m(2),_vm._l((_vm.days),function(item,index){return _c('div',{key:item.id,staticClass:"item_view_card",on:{"click":function($event){return _vm.routeBtn(item, 'YXGS_HOME_USE_LIST')}}},[_c('div',[_c('div',{staticClass:"swipe_title"},[_vm._v(" "+_vm._s(item.chineseName)+" ")]),_c('div',{staticClass:"swipe_title_sub"},[_vm._v(" "+_vm._s(item.englishName)+" ")])]),(index !== _vm.days.length - 1)?_c('div',{staticStyle:{"border-bottom":"#F6F6F6 solid 1px","margin":"10px 0"}}):_vm._e()])})],2):_vm._e(),(_vm.until.length !== 0)?_c('div',{staticClass:"item_view"},[_vm._m(3),_vm._l((_vm.until),function(item,index){return _c('div',{key:item.id,staticClass:"item_view_card",on:{"click":function($event){return _vm.routeBtn(item, 'YXGS_HOME_UNTIL_LIST')}}},[_c('div',[_c('div',{staticClass:"swipe_title"},[_vm._v(" "+_vm._s(item.chineseName)+" ")]),_c('div',{staticClass:"swipe_title_sub"},[_vm._v(" "+_vm._s(item.englishName)+" ")])]),(index !== _vm.until.length - 1)?_c('div',{staticStyle:{"border-bottom":"#F6F6F6 solid 1px","margin":"10px 0"}}):_vm._e()])})],2):_vm._e(),_c('div',{staticClass:"item_view"},[_vm._m(4),_vm._l((_vm.hots),function(item,index){return _c('div',{key:item.id,staticClass:"item_view_card",on:{"click":function($event){return _vm.routeBtn(item, 'YXGS_HOME_HOT_LIST')}}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"hot_index",style:({
              color:
                index === 0
                  ? '#F21448'
                  : index === 1
                  ? '#D15C0E'
                  : index === 2
                  ? '#AB8111'
                  : '#000000',
            })},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',[_c('div',{staticClass:"swipe_title"},[_vm._v(" "+_vm._s(item.chineseName)+" ")]),_c('div',{staticClass:"swipe_title_sub"},[_vm._v(" "+_vm._s(item.englishName)+" ")])])]),(index !== _vm.hots.length - 1)?_c('div',{staticStyle:{"border-bottom":"#F6F6F6 solid 1px","margin":"10px 0"}}):_vm._e()])})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item_news_tag"},[_c('img',{staticStyle:{"width":"40px"},attrs:{"src":require("../../assets/medical/new.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-bottom":"10px"}},[_c('img',{staticStyle:{"width":"90px"},attrs:{"src":require("../../assets/medical/recommend.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-bottom":"10px"}},[_c('img',{staticStyle:{"width":"75px"},attrs:{"src":require("../../assets/medical/day.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-bottom":"10px"}},[_c('img',{staticStyle:{"width":"110px"},attrs:{"src":require("../../assets/medical/until.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin-bottom":"10px"}},[_c('img',{staticStyle:{"width":"90px"},attrs:{"src":require("../../assets/medical/hots.png"),"alt":""}})])
}]

export { render, staticRenderFns }